import {
  Box as MaterialBox,
  Paper as MaterialPaper,
  TableCell as MaterialTableCell,
  TableContainer as MaterialTableContainer,
  Button as MaterialButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { RiSearchLine } from 'react-icons/ri';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import LoadingAnimation from '../../assets/lottie/loading.json';
import { defaultTheme } from '../../styles/defaultTheme';
import LottieSkeleton from '../LottieSkeleton';
import { StyledToolTip } from '../Tooltip/styled';

export const Box = styled(MaterialBox)`
  > button {
    text-transform: none;
    border: 1px solid transparent;
  }
  > button + button {
    margin-left: 5px;
  }

  > button .MuiButton-label {
    white-space: nowrap;
  }

  ${({ three }) =>
    three
      ? css`
          > button:first-child {
            background-color: ${defaultTheme.colors.transparent};
            border-color: ${defaultTheme.colors.primary};
            color: ${defaultTheme.colors.primary};
          }
          > button:nth-child(2) {
            background-color: ${defaultTheme.colors.primary};
            border-color: ${defaultTheme.colors.primary};
          }
          > button:last-child {
            background-color: ${defaultTheme.colors.red};
            border-color: ${defaultTheme.colors.red};
          }
        `
      : css`
          > button:first-child {
            background-color: ${defaultTheme.colors.primary};
            border-color: ${defaultTheme.colors.primary};
          }
          > button:last-child {
            background-color: ${defaultTheme.colors.red};
            border-color: ${defaultTheme.colors.red};
          }
        `}
`;

export const Paper = styled(MaterialPaper)`
  background-color: ${({ bgcolor }) => defaultTheme.colors[bgcolor]};

  overflow-x: hidden;
`;

export const SearchIcon = styled(RiSearchLine)`
  color: ${defaultTheme.colors.primary};
`;

export const TitleButton = styled.div`
  > button {
    text-transform: none;
    background-color: ${defaultTheme.colors.primary};

    &:hover {
      background-color: ${defaultTheme.colors.primary};
    }
  }
`;

export const TableContainer = styled(MaterialTableContainer)`
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
  border-radius: 0.5rem;
`;

export const LoadingTable = ({ cols }) => (
  <tr>
    <td colSpan={cols}>
      <LottieSkeleton
        animation={LoadingAnimation}
        lottieWidth="20rem"
        lottieHeight="10rem"
      />
    </td>
  </tr>
);

LoadingTable.propTypes = {
  cols: PropTypes.number.isRequired,
};

export const TableStyle = styled(MaterialBox)`
  .MuiBox-root {
    font-size: 1.6rem;
    color: ${defaultTheme.typography.lightGray};
  }

  .MuiTableCell-body {
    color: ${defaultTheme.typography.lightGray};
    font-size: 1.2rem;
  }

  .MuiTablePagination-caption {
    font-size: 1.3rem;
    color: ${defaultTheme.typography.lightGray};
    font-weight: 600;
  }

  .MuiTablePagination-select {
    font-size: 1.3rem;
    font-weight: 600;
    color: ${defaultTheme.typography.lightGray};
    margin-top: 0.7rem;
  }

  .MuiTablePagination-selectIcon {
    font-size: 2.1rem;
  }

  .MuiSvgIcon-root {
    font-size: 2.1rem;
  }

  @media (max-width: 1366px) {
    .MuiBox-root {
      font-size: 1.2rem;
    }
    .MuiTableCell-body {
      font-size: 1rem;
    }
    .MuiButton-startIcon {
      width: 2rem;
    }
  }
`;

export const Link = styled(ReactRouterLink)`
  text-decoration: none;
  text-transform: none;
`;

export const TableCell = styled(MaterialTableCell)`
  &:hover > ${StyledToolTip} {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.4s;
    margin-top: 0.5rem;
    overflow-wrap: break-word;
    max-width: 300px;
    user-select: none;
    height: auto;
  }
  ${StyledToolTip} {
    padding: 0.5rem;
  }

  a {
    color: ${defaultTheme.colors.violet};
    font-weight: bold;
  }
`;

export const ResendMailButton = styled(MaterialButton)`
  &.MuiButtonBase-root {
    color: ${defaultTheme.colors.darkGray};
    border-color: ${defaultTheme.colors.darkGray};
    background-color: ${defaultTheme.colors.white};
    width: 17rem;
    justify-content: space-around;

    &:hover {
      background-color: ${defaultTheme.colors.darkerWhite};
    }
  }
`;

export const ResetPasswordButton = styled(MaterialButton)`
  &.MuiButtonBase-root {
    color: ${defaultTheme.colors.white};
    background-color: ${defaultTheme.colors.darkGray};
    width: 17rem;
    justify-content: space-around;

    &:hover {
      background-color: ${defaultTheme.colors.darkerGray};
    }
  }
`;
