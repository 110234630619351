import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
  RiAddFill as PlusIcon,
  RiDeleteBin7Line as DeleteIcon,
  RiLockPasswordLine as PermissionsIcon,
  RiPencilLine as PencilIcon,
  RiMailLine as MailIcon,
  RiSendPlaneFill as SendMailIcon,
  RiKeyFill as PasswordIcon,
} from 'react-icons/ri';
import { getCompanyId } from '../../../services/auth';
import Modal from '../../Modal';
import ResendMailModal from '../../ResendMailModal';
import ResetPasswordModal from '../../ResetPasswordModal';

import * as S from '../styled';
import TableTitle from '../TableTitle';

const UserTable = ({
  data,
  create,
  edit,
  remove,
  link,
  consultingData,
  getAll,
  getDashsByClient,
  clients,
  createPermission,
  permissionsData,
  getAllPermissions,
  removePermission,
  loading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalState, setModalState] = useState(false);
  const [resendMailModalState, setResendMailModalState] = useState(false);
  const [resetPasswordModalState, setResetPasswordModalState] = useState(false);
  const [modalType, setModalType] = useState('create');
  const [userIdState, setUserIdState] = useState(null);
  const [list, setList] = useState(null);
  const [userMap, setUserMap] = useState(new Map());
  const [inputValue, setInputValue] = useState('');
  const companyId = getCompanyId();

  const closeModal = () => setModalState(false);

  const closeResendMailModal = () => setResendMailModalState(false);

  const openResendMailModal = userId => {
    setUserIdState(userId);
    setResendMailModalState(true);
  };

  const onResendMailModalSuccess = () => {
    closeResendMailModal();
    getAll();
  };

  const closeResetPasswordModal = () => setResetPasswordModalState(false);

  const openResetPasswordModal = userId => {
    setUserIdState(userId);
    setResetPasswordModalState(true);
  };

  const onResetPasswordModalSuccess = () => {
    closeResetPasswordModal();
    getAll();
  };

  useEffect(() => {
    setList(data.result);
    setUserMap(new Map(data.result.map(user => [user.id, user])));
  }, [data]);

  const columns = [
    { id: 'name', label: 'Nome', width: '20%', arrow: true },
    { id: 'email', label: 'E-mail', width: '30%', arrow: true },
    { id: 'isAdmin', label: 'Admin', width: '15%', arrow: true },
    { id: 'actions', label: 'Ações', width: '35%', arrow: false },
  ];

  const [orderState, setOrderState] = useState({
    name: false,
    email: false,
    isAdmin: false,
    quantityOfDashs: false,
    actions: false,
  });

  useEffect(() => {
    getAll(inputValue, { skip: page, take: rowsPerPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, getAll, page, rowsPerPage]);

  const returnModal = action => (
    <Modal
      type="user"
      action={action}
      create={create}
      edit={edit}
      link={link}
      remove={remove}
      getAll={getAll}
      opened={modalState}
      variableId={userIdState}
      entity="user"
      options={consultingData}
      onClose={closeModal}
      submitClose={closeModal}
      clients={clients}
      getDashsByClient={getDashsByClient}
      createPermission={createPermission}
      getAllPermissions={getAllPermissions}
      permissionsData={permissionsData}
      removePermission={removePermission}
      loading={loading}
    />
  );

  const handleOrderChange = (id, value) => {
    if (value) {
      const stateCopy = { ...orderState };
      Object.keys(orderState).forEach(key => {
        stateCopy[key] = false;
      });
      stateCopy[id] = value;
      setOrderState(stateCopy);
    }
    orderUsersBy(id, value);
  };

  const orderUsersBy = (id, value) => {
    const dataCopy = [...list];
    dataCopy.sort((userA, userB) => {
      let propA;
      let propB;

      if (id === 'isAdmin') {
        propA = userA.isBoweAdmin ? 'Sim' : 'Não';
        propB = userB.isBoweAdmin ? 'Sim' : 'Não';
      } else {
        propA = userA[id];
        propB = userB[id];
      }

      if (typeof propA === 'string') {
        return value ? propB.localeCompare(propA) : propA.localeCompare(propB);
      }

      return value ? propB - propA : propA - propB;
    });
    setList(dataCopy);
  };

  function createDataTable(name, email, isAdmin, actions) {
    return { name, email, isAdmin, actions };
  }

  const openModal = (type, userId) => {
    if (modalState) {
      setModalState(false);
    }
    setUserIdState(userId);
    setModalType(type);
    setModalState(true);
  };

  const buttonActions = userId => {
    const user = userMap.get(userId);

    return (
      <S.Box display="flex">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<PermissionsIcon />}
          onClick={() => openModal('permissions', userId)}
        >
          Gerenciar permissões
        </Button>
        {!!user && user.withoutPassword && (
          <S.ResendMailButton
            startIcon={<MailIcon />}
            variant="outlined"
            onClick={() => openResendMailModal(userId)}
          >
            Reenviar email
          </S.ResendMailButton>
        )}
        {!!user && !user.withoutPassword && (
          <S.ResetPasswordButton
            startIcon={<PasswordIcon />}
            variant="outlined"
            onClick={() => openResetPasswordModal(userId)}
          >
            Alterar senha
          </S.ResetPasswordButton>
        )}
        <Button
          variant="contained"
          color="secondary"
          startIcon={<PencilIcon />}
          onClick={() => openModal('edit', userId)}
        >
          Editar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={() => openModal('remove', userId)}
        >
          Excluir
        </Button>
      </S.Box>
    );
  };

  const changePage = (event, newPage) => {
    setPage(newPage);
  };

  const changeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = useCallback(
    e => {
      e.preventDefault();

      getAll(inputValue, { skip: page, take: rowsPerPage });
    },
    [getAll, inputValue, page, rowsPerPage],
  );

  const fillTable = list
    ? list.map(element =>
        createDataTable(
          element.name,
          element.email,
          element.isBoweAdmin ? 'Sim' : 'Não',
          buttonActions(element.id),
        ),
      )
    : [];

  return (
    <>
      {returnModal(modalType)}
      <ResendMailModal
        opened={resendMailModalState}
        onCancel={closeResendMailModal}
        buttonIcon={<SendMailIcon />}
        user={userMap.get(userIdState)}
        onSuccess={onResendMailModalSuccess}
      />
      <ResetPasswordModal
        opened={resetPasswordModalState}
        onCancel={closeResetPasswordModal}
        buttonIcon={<SendMailIcon />}
        user={userMap.get(userIdState)}
        onSuccess={onResetPasswordModalSuccess}
      />
      <S.Paper elevation={2}>
        <Box display="flex" flexDirection="column" p={2}>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box width="25%">
              <Box bgcolor="rgba(245,245,245)" borderRadius="5px" px={1}>
                <form onSubmit={handleSearch}>
                  <Box display="flex" alignItems="center">
                    <InputBase
                      value={inputValue}
                      onChange={e => setInputValue(e.target.value)}
                      placeholder="Pesquisar"
                      fullWidth
                    />
                    <Divider orientation="vertical" />
                    <IconButton type="submit" aria-label="search">
                      <S.SearchIcon />
                    </IconButton>
                  </Box>
                </form>
              </Box>
            </Box>
            <S.TitleButton>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PlusIcon />}
                onClick={() => openModal('create')}
              >
                Cadastrar novo usuário
              </Button>
            </S.TitleButton>
          </Box>
          <Box py={3}>
            <Divider />
          </Box>

          <S.TableStyle>
            <S.TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map(columnTitle => (
                      <TableTitle
                        key={columnTitle.id}
                        id={columnTitle.id}
                        orderChange={handleOrderChange}
                        order={orderState[columnTitle.id]}
                        hasArrow={columnTitle.arrow}
                        style={{
                          width: columnTitle.width,
                        }}
                      >
                        {columnTitle.label}
                      </TableTitle>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <S.LoadingTable cols={3} />
                  ) : (
                    fillTable?.map(row => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </S.TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={changePage}
              onChangeRowsPerPage={changeRowsPerPage}
            />
          </S.TableStyle>
        </Box>
      </S.Paper>
    </>
  );
};

UserTable.defaultProps = {
  data: [],
  create: () => null,
  edit: () => null,
  remove: () => null,
  link: () => null,
  consultingData: null,
  getAll: () => null,
  getDashsByClient: () => null,
  clients: [],
  permissionsData: [],
  createPermission: () => null,
  getAllPermissions: () => null,
  removePermission: () => null,
  loading: false,
};

UserTable.propTypes = {
  data: PropTypes.any,
  create: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
  link: PropTypes.node,
  consultingData: PropTypes.node,
  getAll: PropTypes.func,
  getDashsByClient: PropTypes.func,
  clients: PropTypes.any,
  permissionsData: PropTypes.any,
  createPermission: PropTypes.func,
  getAllPermissions: PropTypes.func,
  removePermission: PropTypes.func,
  loading: PropTypes.bool,
};

export default UserTable;
