export const defaultTheme = {
  colors: {
    transparent: 'transparent',
    primary: '#4A45D8',
    secondary: '#858EF3',
    third: '#222137',
    fourth: '#4BC0C0',
    seventh: '#E7F6F6',
    eighth: '#F6F6FD',
    white: '#FFFFFF',
    darkerWhite: '#F3F3F3',
    red: '#f5284c',
    grayLight: '#FAFAFA',
    violet: '#6B68E8',
    gray: '#B7B7B7',
    darkGray: '#777777',
    darkerGray: '#555555',
    warning: '#FF879E',
  },
  customColor: {
    violet: {
      a10: '#807CE31A',
    },
  },
  alert: {
    error: '#F44336',
    warning: '#FF9800',
    info: '#2196F3',
    success: '#4CAF50',
  },
  font: {
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
    },
  },
  typography: {
    transparent: 'transparent',
    primary: '#000000',
    secondary: '#FFFFFF',
    third: '#4A45D8',
    fourth: '#858EF3',
    sixth: '#4BC0C0',
    seventh: '#222137',
    red: '#f5284c',
    gray: '#B7B7B7',
    lightGray: '#757575',
  },
};
