import { Button, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';
import TextInput from '../Inputs/Text/index';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55rem;
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    &.MuiTypography-root {
      font-weight: ${theme.font.regular};
      color: ${theme.colors.darkGray};
    }
  `}
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 68% 30%;
  place-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 4rem;
`;

export const EmailInput = styled(TextInput)``;

export const CopyLinkButton = styled(Button)`
  height: 100%;
`;
