import { API } from './api';

export const getAllUser = async ({
  companyId,
  searchValue = '',
  skip,
  take,
}) => {
  const api = await API({ auth: true, refresh: true });

  const searchString = searchValue ? `&searchValue=${searchValue}` : '';
  const paginateOptions = skip || take ? `&skip=${skip}&take=${take}` : '';

  return api.get(
    `/user?companyId=${companyId}${searchString}${paginateOptions}`,
  );
};

export const getUser = async () => {
  const api = await API({ auth: true, refresh: true });
  return api.get('/user');
};

export const getUserById = async userId => {
  const api = await API({ auth: true, refresh: true });
  return api.get(`/user/${userId}`);
};

export const postCreateUser = async bodyParams => {
  const api = await API({ auth: true, refresh: true });
  return api.post('/user', bodyParams);
};

export const patchEditUser = async ({
  bodyParams,
  userId,
  formData = true,
}) => {
  const api = await API({ auth: true, refresh: true, formData });
  return api.patch(`/user/${userId}`, bodyParams);
};

export const deleteUser = async userId => {
  const api = await API({ auth: true, refresh: true });
  return api.delete(`/user/${userId}`);
};

export const postRequestPassword = async bodyParams => {
  const api = await API({ auth: false, refresh: false, formData: false });
  return api.post('/user/reset-password', bodyParams);
};

export const postCreatePassword = async bodyParams => {
  const api = await API({ auth: false, refresh: false, formData: false });
  return api.post('/user/create-password', bodyParams);
};
