import { API } from './api';

export const getAllUsers = async () => {
  const api = await API({ auth: true, refresh: true });
  return api.get('/user');
};

export const postCreateUser = async bodyParams => {
  const api = await API({ auth: true, refresh: true });
  return api.post('/user', bodyParams);
};

export const patchEditUser = async ({ bodyParams, userId }) => {
  const api = await API({ auth: true, refresh: true });
  return api.post(`/user/${userId}`, bodyParams);
};

export const deleteUser = async userId => {
  const api = await API({ auth: true, refresh: true });
  return api.delete(`/user/${userId}`);
};

export const getUserLength = async () => {
  const api = await API({ auth: true, refresh: true });
  return api.get('/user/length');
};
